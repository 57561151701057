'use strict'

angular.module('cb.core.notification-service', [])
    .factory('NotificationService', ["$location", "pinesNotifications", function ($location, pinesNotifications) {
        var notificationService = {

            alert: function (title, text, type, options) {
                var notification = pinesNotifications.notify({
                    title: title,
                    text: text,
                    type: type
                });

                notification.options.delay = 1500;
            },

            confirm: function (title, text, options){
                if (options === undefined) {
                    options = {
                    };
                }

                var notification = pinesNotifications.notify({
                    title: title,
                    text: text,
                    icon: 'glyphicon glyphicon-question-sign',
                    hide: false,
                    confirm: {
                        confirm: true
                    },
                    buttons: {
                        closer: false,
                        sticker: false
                    },
                    history: {
                        history: false
                    }
                });

                if (typeof options.delay === "number") {
                    notification.options.delay = options.delay;
                };

                if (typeof options.hide === "boolean") {
                    notification.options.hide = options.hide;
                };

                return notification;
            }
        };

        return notificationService;
    }]);
